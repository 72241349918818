<template>
  <div class="tracker-container">
    <div class="temp-container" @click="showKeyboard = !showKeyboard"></div>

    <div
      class="scorecard-keyboard"
      v-hammer:swipe="moveScores"
      :class="{ active: showKeyboard }"
    >
      <transition name="fade" mode="out-in">
        <div class="penalties-container" v-if="shownVerticalPosition == 0">
          My personal stats: <img src="@/assets/img/password-lock.svg" />
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="score-stats-container" v-if="shownVerticalPosition == 1">
          <div class="scores">
            <div class="lower" @click="moveScores({ direction: 4 })">
              <img src="@/assets/img/lower-scores.svg" alt="" />
            </div>
            <div class="scores-inner-container">
              <div
                class="score"
                @click="selectedScoreManager(n)"
                v-for="n in availableScores"
                :key="n"
                :class="{ selected: selectedScore == n }"
              >
                {{ n }}
              </div>
            </div>
            <div class="higher" @click="moveScores({ direction: 2 })">
              <img src="@/assets/img/higher-scores.svg" alt="" />
            </div>
          </div>

          <div class="stats">
            <img
              src="@/assets/img/circle-hit.svg"
              :class="{ selected: selectedStats.isCircleHit }"
              @click="statsManager('isCircleHit')"
            />
            <img
              src="@/assets/img/inside-putt.svg"
              :class="{ selected: selectedStats.isInsidePutt }"
              @click="statsManager('isInsidePutt')"
            />
            <img
              src="@/assets/img/outside-putt.svg"
              :class="{ selected: selectedStats.isOutsidePutt }"
              @click="statsManager('isOutsidePutt')"
            />
            <img
              src="@/assets/img/ob.svg"
              :class="{ selected: selectedStats.isOutOfBounds }"
              @click="statsManager('isOutOfBounds')"
            />
          </div>
          <div class="verify">
            <img
              src="@/assets/img/confirm-score.svg"
              :class="{ selectable: selectedScore != 0 }"
              @click="submit()"
            />
          </div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="penalties-container" v-if="shownVerticalPosition == 2">
          Warnings: <img src="@/assets/img/phone-sky.svg" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TheTracker",
  data() {
    return {
      showKeyboard: false,
      scoreScrollPosition: 0,
      currentPar: 3,
      shownScoresModifier: 0,
      shownVerticalPosition: 1,
      selectedScore: 0,
      selectedStats: {
        isCircleHit: false,
        isInsidePutt: false,
        isOutsidePutt: false,
        isOutOfBounds: false,
      },
    };
  },
  computed: {
    availableScores() {
      let scores = [];
      let currentPar = this.currentPar + this.shownScoresModifier;
      scores.push(currentPar - 1);
      scores.push(currentPar);
      scores.push(currentPar + 1);
      scores.push(currentPar + 2);

      return scores;
    },
  },
  watch: {
    selectedScore: function () {
      this.selectedStats.isCircleHit = false;
      this.selectedStats.isInsidePutt = false;
      this.selectedStats.isOutsidePutt = false;
      this.selectedStats.isOutOfBounds = false;
    },
  },
  methods: {
    submit() {
      if (this.selectedScore != 0) {
        this.showKeyboard = false;
      }
    },
    selectedScoreManager(n) {
      if (this.selectedScore == n) {
        this.selectedScore = 0;
      } else {
        this.selectedScore = n;
      }
    },
    statsManager(stat) {
      console.log(stat);

      if (this.selectedScore != 0) {
        if (stat == "isCircleHit") {
          if (this.selectedScore == this.currentPar - 1) {
            if (this.selectedStats.isCircleHit == true) {
              this.selectedStats.isCircleHit = false;
              this.selectedStats.isInsidePutt = false;
            } else {
              this.selectedStats.isCircleHit = true;
              this.selectedStats.isInsidePutt = true;

              this.selectedStats.isOutsidePutt = false;
            }
          } else {
            if (this.selectedStats.isCircleHit == true) {
              this.selectedStats.isCircleHit = false;
            } else {
              this.selectedStats.isCircleHit = true;

              if (this.selectedScore >= this.currentPar) {
                this.selectedStats.isInsidePutt = false;
              }
            }
          }
        }
        if (stat == "isInsidePutt") {
          if (this.selectedStats.isInsidePutt == true) {
            if (this.selectedScore == this.currentPar - 1) {
              this.selectedStats.isCircleHit = false;
            }

            this.selectedStats.isInsidePutt = false;
          } else {
            this.selectedStats.isOutsidePutt = false;

            if (this.selectedScore == this.currentPar - 1) {
              this.selectedStats.isCircleHit = true;
            } else {
              this.selectedStats.isCircleHit = false;
            }
            this.selectedStats.isInsidePutt = true;
          }
        }
        if (stat == "isOutsidePutt") {
          this.selectedStats.isInsidePutt = false;

          if (this.selectedStats.isOutsidePutt == true) {
            this.selectedStats.isOutsidePutt = false;
          } else {
            this.selectedStats.isOutsidePutt = true;
          }
        }
        if (stat == "isOutOfBounds") {
          if (this.selectedScore > 2) {
            if (this.selectedStats.isOutOfBounds == true) {
              this.selectedStats.isOutOfBounds = false;
            } else {
              this.selectedStats.isOutOfBounds = true;
            }
          }
        }
      }
    },
    moveScores(event) {
      if (event.direction == 2) {
        this.shownScoresModifier += 2;
      } else if (event.direction == 4) {
        if (this.availableScores[0] == 2) {
          this.shownScoresModifier--;
        }
        if (this.availableScores[0] > 1) {
          this.shownScoresModifier -= 2;
        }
      } else if (event.direction == 8) {
        if (this.shownVerticalPosition == 2) this.shownVerticalPosition--;
        else this.shownVerticalPosition++;
      } else if (event.direction == 16) {
        if (this.shownVerticalPosition == 0) this.shownVerticalPosition++;
        else this.shownVerticalPosition--;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("setMainNavState", false);
  },
  beforeDestroy() {
    this.$store.dispatch("setMainNavState", true);
  },
};
</script>
<style lang="scss" scoped>
.tracker-container {
  max-width: 600px;
  background-color: $mouse;
  margin: 0 auto;
  min-height: 400px;

  .temp-container {
    height: 300px;
    background-color: $elephant;
  }
}

.scorecard-keyboard {
  height: 300px;
  max-height: 0;
  display: flex;
  background-color: $midnight;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  transition: all ease 0.3s;

  &.active {
    max-height: 300px;
  }

  .score-stats-container {
    display: block;
    height: 200px;
    width: 100%;
    transition: all ease 0.5s;
    padding: 15px 5px;

    .higher,
    .lower {
      opacity: 0.3;
      width: 25px;
    }

    .scores {
      height: 80px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
    }
    .stats {
      height: 100px;
      display: flex;
      width: 100%;
      padding: 24px;
      align-items: center;
      justify-content: space-around;

      img {
        width: 60px;
        opacity: 0.2;
        transition: all ease 0.3s;

        &.selected {
          opacity: 1;
        }
      }
    }
    .verify {
      height: 100px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;

      img {
        width: 60px;
        opacity: 0.3;

        &.selectable {
          opacity: 1;
        }
      }
    }
    .scores-inner-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }

    .score {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid white;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      @include Gilroy-Bold;
      color: white;
      margin: 25px 0px;
      font-size: 32px;
      transition: all ease 0.3s;

      &.selected {
        background-color: white;
        color: $midnight;
        @include Gilroy-Black;
      }
    }
  }

  .penalties-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100px;
    position: absolute;
    padding: 0px 30px;
    width: 100%;
    left: 0;
    top: 0;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
</style>
